'use client';

import { useAppSelector } from '@/Hooks/redux';
import { LocalhostNews } from '@/Widgets/LocalNews/LocalhostNews';

import style from './page.module.sass';

import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useDispatch } from 'react-redux';
import Options from '@/Widgets/Options/options';

// ---------------------
import { getPosts, ClearAll } from '@/Redux/saga/getPostArticle';
import { ChildComponent } from '@/Hooks/fetcher';
import { Message } from '@/lib/PostMessage';

export default function MainPage() {
	const { newsViewBollean } = useAppSelector(state => state.NewsViewSlice);
	const { CurrentFontSize } = useAppSelector(state => state.HeaderState);

	const WhiteList = useAppSelector(state => state.SourseSlice);

	const dispatch = useDispatch();
	const Article = useAppSelector(state => state.getPostArticle);

	// observer
	const { ref, inView } = useInView({
		threshold: 1
	});

	// pagination request
	useEffect(() => {
		if (inView) {
			const arr = Article.data.map((el: any) => el.id);
			dispatch(
				getPosts({
					pages: 50,
					whiteList: WhiteList.WhiteSourses,
					filter_ids: arr
				})
			);
		}
	}, [inView]);

	// initial request
	useEffect(() => {
		dispatch(
			getPosts({
				pages: 50,
				whiteList: WhiteList.WhiteSourses
			})
		);
	}, [dispatch, WhiteList.WhiteSourses]);

	return (
		<>
			<Message
				funct={getPosts({
					pages: 50,
					whiteList: WhiteList.WhiteSourses
				})}
				rest={ClearAll}
			/>
			{/* <input
				type='button'
				onClick={() => notifyMe()}
				value='Notification'
			></input> */}
			<Options />
			<LocalhostNews />
			<div className={style.childTitleWrapper}>
				<h1
					className={style.childTitle}
					style={{ fontSize: `${15 + CurrentFontSize}px` }}
				>
					ГЛАВНЫЕ НОВОСТИ
				</h1>
			</div>
			<section
				className={
					newsViewBollean == false ? style.NewsContLine : style.NewsCont
				}
			>
				<ChildComponent elAll={Article} inputRef={ref} />
			</section>
		</>
	);
}
